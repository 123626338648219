import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

export default function TeamCard ({ team, setShowTeam }) {
  const image = require(`assets/team/${team.image}.jpg`)
  const flag = team.city ? require(`assets/${team.city}.png`) : ''
  return (
    <Grid size={{ xs: 12, lg: 6 }}>
      <Paper elevation={0}>
        <Grid container>
          <Grid size={{ xs: 2, sm: 3 }}>
            <Box component='img' src={image} width='100%' sx={{ maxWidth: 180, ml: 2, mt: 2, borderRadius: 2, cursor: 'pointer' }} onClick={() => setShowTeam(image)} />
          </Grid>
          <Grid size={{ xs: 10, sm: 9 }} sx={{ p: 3 }}>
            <Typography variant='subtitle2' fontWeight='bold'>{team.name} {team.flag}</Typography>
            <Typography variant='body2'>{team.title}</Typography>
            {team.city &&
              <Box component='img' src={flag} width='100%' sx={{ maxWidth: 84, display: 'block', ml: 'auto' }} />}
            <Divider component='div' sx={{ mt: 0.4 }} />
            {team.description && team.description}
          </Grid>
          {team.education &&
            <Grid size={12} sx={{ px: 3 }}>
              <Typography variant='body2'><b>Education:</b> {team.education}</Typography>
            </Grid>}
          <Grid size={12} sx={{ p: 2, textAlign: 'center' }}>
            {team.citation &&
              <Typography variant='body2' sx={{ fontStyle: 'italic' }}>&quot;{team.citation}&quot;</Typography>}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
