import { InstagramEmbed } from 'react-social-media-embed'
import Grid from '@mui/material/Grid2'

export default function Insta () {
  const links = [
    'https://www.instagram.com/p/C7v2kJ2I0Yp/',
    'https://www.instagram.com/p/C7ZU7Hio9Rn/',
    'https://www.instagram.com/p/C8pbVt_yLdH/',
    'https://www.instagram.com/p/C8jnPq7RzAn/',
    'https://www.instagram.com/p/C7pHNQ3vE4d/',
    'https://www.instagram.com/p/C7R5r4wIDB3/'
  ]
  return (
    <Grid container spacing={2}>
      {links.map((link, i) => (
        <Grid
          key={i}
          size={{ xs: 12, md: 4 }}
          sx={{ minHeight: 600, maxWidth: '375px !important', margin: '0 auto' }}
        >
          <InstagramEmbed url={link} />
        </Grid>
      ))}
    </Grid>
  )
}
