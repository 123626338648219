import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Comps from 'assets/comps.jpg'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import HelmetMeta from '../../layouts/HelmetMeta'
import Pdf from 'assets/pdf.png'
import Docx from 'assets/docx.png'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Announcement from 'assets/compDocs/Skate_Emirates_2025_Announcement_v2.pdf'
import EntryForm from 'assets/compDocs/Skate_Emirates_2025_Entry_Form.docx'
import PPCForm from 'assets/compDocs/Skate_Emirates_2025_PPC_Form.docx'

export default function Competitions () {
  const [showResults, setShowResults] = useState(false)
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Container sx={{ pt: 12 }}>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Skate Emirates'
        description='We host anually the largest ice skating competion in the Middle East. We hope to see you in our next ice skating competion.'
      />
      <Box sx={{ width: '100%', height: 420, backgroundImage: `url(${Comps})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '0 50%' }}>
        <Typography variant='h1' sx={{ p: 4, color: '#ffffff', textShadow: '0 2px 2px #000' }}>Skate Emirates Competition</Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ p: 4 }} elevation={0}>
          <b>International Interclub Figure Skating Competition 18 – 20 April 2025</b><br /><br />
          <Typography variant='body2'>
            All club members are invited to participate in the 2025 SKATE EMIRATES
            International Interclub Figure Skating Competition to be held in Abu Dhabi, United
            Arab Emirates. This competition will include all levels from Foundation up to Seniors
            and will be judged using the ISU International Judging System (IJS).
            All competitors must be registered by their respective club or federation. <br />
          </Typography>
          <Typography sx={{ my: 2 }} variant='body2' fontWeight='bold'>Participation Documents:</Typography>

          <MenuItem sx={{ mb: 2, width: 'fit-content' }}>
            <ListItemIcon>
              <Box component='img' src={Pdf} width='32px' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' sx={{ color: 'text.primary' }}>
                <Link href={Announcement} target='_blank' rel='noreferrer'>
                  &nbsp;Skate Emirates 2025 Announcement
                </Link>
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ mb: 2, width: 'fit-content' }}>
            <ListItemIcon>
              <Box component='img' src={Docx} width='32px' />
            </ListItemIcon>
            <ListItemText sx={{ color: 'text.primary' }}>
              <Typography variant='body2' sx={{ color: 'text.primary' }}>
                <Link href={EntryForm} target='_blank' rel='noreferrer'>
                  &nbsp;Skate Emirates 2025 Entry Form
                </Link>
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ mb: 2, width: 'fit-content' }}>
            <ListItemIcon>
              <Box component='img' src={Docx} width='32px' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' sx={{ color: 'text.primary' }}>
                <Link href={PPCForm} target='_blank' rel='noreferrer'>
                  &nbsp;Skate Emirates 2025 PPC Form
                </Link>
              </Typography>
            </ListItemText>
          </MenuItem>
        </Paper>
      </Box><br />

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ p: 4 }} elevation={0}>
          View past competitions results<br />
          <Button vartiant='contained' onClick={() => setShowResults('https://skate-emirates.s3.eu-central-1.amazonaws.com/2022/index.htm')}>2022</Button>
          <Button vartiant='contained' onClick={() => setShowResults('https://skate-emirates.s3.eu-central-1.amazonaws.com/2023/index.htm')}>2023</Button>
          <Button vartiant='contained' onClick={() => setShowResults('https://hunskate.hu/external/mukorcsolya/2023_2024/SKE2024/index.htm')}>2024</Button>
        </Paper>
      </Box><br />

      {!!showResults &&
        <Dialog
          aria-labelledby='results'
          aria-describedby='competition-results'
          fullScreen={fullScreen}
          maxWidth='xxl'
          open={!!showResults}
          onClose={() => setShowResults(false)}
        >
          <DialogActions><Button onClick={() => setShowResults(false)}>Close</Button></DialogActions>
          <DialogContent sx={{ minWidth: '98vw', height: '90vh' }}>
            <iframe
              width='100%'
              height='100%'
              style={{ border: 0 }}
              frameBorder='0'
              referrerPolicy='no-referrer-when-downgrade'
              loading='lazy'
              allowFullScreen
              aria-hidden='false'
              tabIndex='0'
              src={showResults}
            />
          </DialogContent>
        </Dialog>}

    </Container>
  )
}
