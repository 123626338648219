import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { Link } from 'react-router-dom'

export const ClassDetails = () => (
  <Grid size={12} sx={{ my: 4 }}>
    <Typography variant='h5' component='h2' my={4}>WHAT WE TEACH AND TRAIN?</Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Rotation (rotator, harness & spinner):
    </Typography>
    <Typography variant='subtitle2'>
      A set of exercises aimed at developing the vestibular apparatus, sense of centering, sense of balance on the floor and in the air.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Dynamic:
    </Typography>
    <Typography variant='subtitle2'>
      A set of exercises that include active body movement such as running, jumping, arm and leg swings, squats, lunges,
      push-ups, etc.

      With dynamic loads, we:<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> successfully build muscle
      mass;<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> have a positive effect on joints and the overall development of the motor system;<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> accelerate metabolic processes;<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> improve the functioning of the heart and blood vessels.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Stretching:
    </Typography>
    <Typography variant='subtitle2'>
      Stretching - is a set of exercises aimed at intensive stretching of ligaments, as well as all muscle groups, increasing their elasticity, sensitivity and tone.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Core/Stability:
    </Typography>
    <Typography variant='subtitle2'>
      Core stability is a person&apos;s ability to stabilise control the position and movement of their core.

      Core group training is aimed at working out the core muscles of the abdomen, back, buttocks. This muscle, which
      holds the spine, allows you to have a beautiful and even posture.<br />

      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> Having a strong, stable
      core helps us to prevent injuries and allows us to perform at our best.<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> In order to protect the
      back, ideally we want to create 360 degrees of stiffness around the spine as we move, run, jump, throw, lift
      objects and transfer force throughout our body.<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> We do this when all of the
      muscles in our hips, torso and shoulders work together.<br /><br />

      Get six-pack abs, correct and beautiful posture, strengthen your back muscles, get rid of problems with your spine
      – all this can be achieved with the help of core training.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Plyometric:
    </Typography>
    <Typography variant='subtitle2'>
      Plyometrics - are a group of exercises that help improve strength, speed and endurance. Includes many different jumping exercises.
      Plyometric exercises effectively improve a range of physical performance indicators, including jump strength and height, running economy, agility, sprint speed and endurance.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Choreography:
    </Typography>
    <Typography variant='subtitle2'>
      In the modern sense, choreography is the art of conveying an image, plot or idea to music using body plasticity. It is in this understanding that it is applicable to figure skating.
      Choreography is a very important class for figure skaters; it develops plasticity, hones the positions of the body, arms and legs. During choreography classes, our skaters perform classical exercises at the barre, study dances of different nationalities around the world, as well as modern dances. This allows skaters to try their hand at all styles and directions. This gives a skater the opportunity to choose a suitable style for their competitions.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Jump off ice:
    </Typography>
    <Typography variant='subtitle2'>
      During this class, off-ice jumping techniques are honed. Using equipment and exercises that are designed  specifically to help a skater  to performed the jump and to strengthen the correct jumping technique and position.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Rotation:
    </Typography>
    <Typography variant='subtitle2'>
      Rotation is the position of a skater&apos;s body in the air while he spins in a jump. Usually this is a position of the body stretched out - arms pressed to the body, legs crossed. Twist-the speed at which a jump rotates in the air. It is believed that fast twist helps in multi-rotation jumps.
      During this class, the rotation position is practiced using all necessary equipment.
    </Typography>

    <Typography variant='h5' sx={{ my: 2, color: '#e82730' }}>
      Adults off ice:
    </Typography>
    <Typography variant='subtitle2'>
      Three days off-ice for adults include three different workouts:<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> choreography (classical
      barre, dancing, stretching),<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> stamina<br />
      <CircleOutlinedIcon size='small' color='primary' sx={{ verticalAlign: 'text-top' }} /> jumps and spins off
      ice.<br />
    </Typography><br />

    <Typography variant='subtitle2' sx={{ my: 2 }}>You can read more about ice skating benefits <Link to='/knowledge' target='_blank'>here</Link>.</Typography>
  </Grid>
)
