import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link, useParams } from 'react-router-dom'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import { useEffect, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { ClassDetails } from './ClassDetails'
import ModalRegistration from '../../components/RegistrationForms/ModalRegistration'
import HelmetMeta from '../../layouts/HelmetMeta'

export default function ClassesAbuDhabi () {
  const { article } = useParams()
  const [location, setLocation] = useState(null)
  const readingRef = useRef(null)
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (article) {
      setTimeout(() => readingRef.current.scrollIntoView(), 100)
    }
  }, [article])

  return (
    <Container>
      <HelmetMeta
        title='EMIRATES SKATING CLUB | Ice Skating Classes in Abu Dhabi'
        description='Whether you are looking to perfect your technique or simply enjoy a leisurely skate, join Emirates Skating Club classes in Abu Dhabi!'
      />
      <Box sx={{ pt: 14, pb: 2 }}>
        <Typography variant='h5' component='h1'>SCHEDULE OF CLASSES AT ESC ABU DHABI</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, lg: 8 }}>
          <Box sx={{ height: 664, width: '100%', background: '#ddd' }}>
            {showSpinner &&
              <Box sx={{ width: '100%', pt: 12, textAlign: 'center' }}>
                <CircularProgress size={24} />
              </Box>}
            <iframe
              frameBorder='0'
              id='register'
              title='registration'
              style={{ width: '100%', height: '100%' }}
              onLoad={() => setShowSpinner(false)}
              src='https://forms.activitree.com/calendars/public/WSQ73kswGwPiEdXwW/week'
            />
          </Box>
          <Box>
            <Button variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => setLocation('abh')}>Register</Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, lg: 4 }} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ my: 'auto' }}>
            <Typography variant='subtitle2'>
              At the Emirates Skating Club in Abu Dhabi, ice-skating becomes an exhilarating escape from the desert heat. This premier facility boasts a modern ice rink where skaters can glide effortlessly in a cool, crisp environment. Catering to both recreational and competitive skaters, the club offers a range of programs from beginner lessons to advanced training sessions. Whether you are looking to perfect your technique or simply enjoy a leisurely skate, the Emirates Skating Club provides a vibrant and welcoming atmosphere for all ages.
            </Typography>
            <Box sx={{ mt: 2 }} ref={readingRef}>
              <Button variant='contained' component={Link} to='/team/abu-dhabi/ice-team' target='_blank'>Coaches</Button>
              <Button variant='contained' sx={{ ml: 2 }} component={Link} to='/locations' target='_blank'>Location</Button>
            </Box>
          </Box>
        </Grid>
        <ClassDetails />
      </Grid>
      {!!location && <ModalRegistration {...{ location, setLocation }} />}
    </Container>
  )
}
