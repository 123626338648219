import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import footerRoutes from '../../footer.routes'

export default function Footer () {
  const { brand, socials, menus, copyright } = footerRoutes

  return (
    <Box component='footer'>
      <Container>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 3 }} sx={{ ml: 'auto', mb: 3 }}>
            <Box>
              <Link to={brand.route}>
                <Box component='img' src={brand.image} alt={brand.name} maxWidth='9rem' mb={2} />
              </Link>
              <Typography variant='h6'>{brand.name}</Typography>
            </Box>
            <Box display='flex' alignItems='center' mt={3}>
              {socials.map(({ icon, link }, key) => (
                <Box
                  key={link}
                  component='a'
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  mr={key === socials.length - 1 ? 0 : 2}
                >
                  {icon}
                </Box>
              ))}
            </Box>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} size={{ xs: 6, md: 2 }} sx={{ mb: 3 }}>
              <Typography
                display='block'
                variant='button'
                fontWeight='bold'
                textTransform='capitalize'
                mb={1}
              >
                {title}
              </Typography>
              <Box component='ul' p={0} m={0} sx={{ listStyle: 'none' }}>
                {items.map(({ name, route, href }) => (
                  <Box key={name} component='li' p={0} m={0} lineHeight={1.25}>
                    {href
                      ? (
                        <Typography
                          component='a'
                          href={href}
                          target='_blank'
                          rel='noreferrer'
                          variant='button'
                          fontWeight='regular'
                          textTransform='capitalize'
                        >
                          {name}
                        </Typography>
                        )
                      : (
                        <Link style={{ color: 'black', textTransform: 'capitalize', fontWeight: 'regular', fontSize: '75%', lineHeight: '32px' }} to={route}>
                          {name}
                        </Link>
                        )}
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid size={12} sx={{ textAlign: 'center', my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
